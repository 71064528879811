
type Options = {
  priceClasses: string, 
  suffixClasses: string,
  origPriceClasses: string,
  origSuffixClasses: string, 
  percentClasses: string,
  valueClasses: string
}

export class DynamicPricing {

  priceClasses: string;
  suffixClasses: string;
  origPriceClasses: string;
  origSuffixClasses: string;
  percentClasses: string;
  valueClasses: string;
  elems: NodeListOf<HTMLElement>;
  pricesToChange: object[];
  yearly: boolean;

  domain: string = '';
  coupon: string = '';
  url: string = '';


  /*
   *  CTOR
   *  Takes CSS classes that wil be applied to DOM elements if the price is adjusted
   *
   *
   */
  constructor(options: Options) {

    this.priceClasses = options.priceClasses;
    this.suffixClasses = options.suffixClasses;
    this.origPriceClasses = options.origPriceClasses;
    this.origSuffixClasses = options.origSuffixClasses;
    this.percentClasses = options.percentClasses;
    this.valueClasses = options.valueClasses;

    // Read the DOM and add any element with a data-qkn-sku data element to the arry of elements
    // These are DOM elements we will want to change base on the data coming back from the pricing API 
    // or the saaved pricing API data from local storage
    this.elems = document.querySelectorAll('[data-qkn-sku]');
    this.pricesToChange = [];
    this.yearly = false;

    // Create the prices to change array from the elements we already got, but add in the type of element 
    // we will be changing these type include :
    // data-qkn-type="percent"
    // data-qkn-type="originalPrice"
    // data-qkn-type="price_cents" etc. a full list can be found in the formatValue() function below
    this.elems.forEach((elem) => {

      let type: string | undefined = '';

      type = (elem.dataset.qknType);
      this.pricesToChange.push({sku: elem.dataset.qknSku, type: type, elem: elem});
    });
  }

  /*
   *  Main method called to populate the price JSON and update DOM
   */
  async adjustPrices() {
    let data: any  = await this.getPrices();

    this.pricesToChange.forEach((priceElem: any) => {
      data.prices.forEach((price: any) => {
        if (priceElem.sku == price.sku) {
          let formattedValue = this.formatValue(price, priceElem.elem);
          if (formattedValue) {
            let beforeEvt = new CustomEvent('beforePriceUpdate', {
              detail: {
                priceInfo: price,
                type: priceElem.type
              }
            });
            let afterEvt = new CustomEvent('afterPriceUpdate', {
              detail: {
                priceInfo: price,
                type: priceElem.type
              }
            });
            priceElem.elem.dispatchEvent(beforeEvt);

            if (this.coupon) {
              priceElem.elem.textContent = formattedValue;
              priceElem.elem.className = this.getClassName(price, priceElem.elem);
            }
            else {
              if ((priceElem.type === 'price') || (priceElem.type === 'price_dollars') || (priceElem.type === 'price_cents')) {
                priceElem.elem.textContent = formattedValue;
                priceElem.elem.className = this.getClassName(price, priceElem.elem);
              }
              if ((priceElem.type === 'suffix') || (priceElem.type === 'full_suffix')) {
                priceElem.elem.textContent = formattedValue;
                priceElem.elem.className = this.getClassName(price, priceElem.elem);
              }
            }
            priceElem.elem.dispatchEvent(afterEvt);
          }
        }
      });
    });
  }

  /*
   *  Retrieve the JSON pricing data fromthe backend, passing any appropriate coupon
   */
  async getPrices() {

    const local_data: string = localStorage.getItem('qknPriceData') || '';
    
    const one_hour_ago = Date.now() - (1000 * 60 * 60);
    const five_minutes_ago = Date.now() - (1000 * 60 * 5);
    

    let result: any | null = null;
    if (local_data != '' ) {
      result = JSON.parse(local_data);
    }
    const data_time = Math.floor(result ? result?.time : 0);
    const yearly_set = result ? result?.yearly : 0;
    
    

    const subscriber_cookie = this.getCookie('qkn_subscriber');
    let cookie = null;
    let cookie_time = 0;
    if (subscriber_cookie) {
      try {
        cookie = JSON.parse(subscriber_cookie);
        cookie_time = cookie.created;
      }
      catch (e) {
      }
    }

    // data_time comes from the saved pricing data in localstorage
    // 1) If the data was saved less than an hour ago we just return the data, otherwise we go and fetch it again.
    // 2) Check if the yearly flag has changed, the data can be fetched in yearly or monthly formats depending on this.yearly
    //    if that doesn't match the saved data refetch the data and save it.
    // 3) Check if the subscriber cookie was created less than 5 minutes ago, if it was the subs section was likely updated/refreshed
    //    so we should refetch the data and and recheck the subs
    if (result && (data_time > one_hour_ago) && 
        (this.yearly === yearly_set) && 
        (!cookie_time || (cookie_time > five_minutes_ago))) {
      return result; // return the data from localstorage
    }
    else {

      if (!this.url) {
        console.log('QKNPricing - no URL provided');
        return {};
      }

      let cURL = this.url;

      if (this.coupon) {
        if (cURL.indexOf('?') !== -1) {
          cURL = cURL + '&coupon=' + this.coupon;
        }
        else {
          cURL = cURL + '?coupon=' + this.coupon;
        }
      }
      if (this.yearly) {
        if (cURL.indexOf('?') !== -1) {
          cURL = cURL + '&yearly=1';
        }
        else {
          cURL = cURL + '?yearly=1';
        }
      }
      try {
        let response = await fetch(cURL);
        let data = await response.json();
        // Based on the subscriber cookie, if there is one, delete
        // the coupon associated with any SKUs in the same product line the user already has
        // so they don't see a discount they aren't eligible for  
        data = this.filterPricesBySubscriptions(data, cookie);

        let prices = {time: Date.now(), yearly: this.yearly, prices: data};
        localStorage.setItem('qknPriceData', JSON.stringify(prices));
        return prices;
      }
      catch (e: any) {
        console.error('QKNPricing called ' + cURL);
        console.error('error : ' + e.message);
      }
      return;
    }
  }

  clearData() {
    localStorage.removeItem('qknPriceData');
  }

  setCoupon(coupon: string) {
    this.coupon = coupon;
  }

  setURL(url: string) {
    this.url = url;
  }

  setDomain(domain: string) {
    this.domain = domain;
  }

  setYearly(yearly: boolean) {
    this.yearly = yearly;
  }

  formatValue(price: any, elem: any) {
    let value = null;

    if (elem.dataset.qknType === 'suffix') {
      value = price.price_suffix ? price.price_suffix : '';
    }
    if (elem.dataset.qknType === 'full_suffix') {
      value = price.full_price_suffix ? price.full_price_suffix : '';
    }
    if (elem.dataset.qknType === 'price') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.currency_symbol + price.coupon_price;
      }
      else {
        value = price.currency_symbol + price.price;
      }
    }
    if (elem.dataset.qknType === 'price_dollars') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.currency_symbol + price.coupon_price.split('.')[0];
      }
      else {
        value = price.currency_symbol + price.price.split('.')[0];
      }
    }
    if (elem.dataset.qknType === 'price_cents') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.coupon_price.split('.')[1];
      }
      else {
        value = price.price.split('.')[1];
      }
      if (price.coupon_price == '' && this.coupon && price.free_trial) {
        value = '00';
      }
    }
    if (elem.dataset.qknType === 'originalPrice') {
      if (price.coupon_price !== '') {
        value = price.currency_symbol + price.price;
      }
    }
    if (((elem.dataset.qknType === 'percent') || (elem.dataset.qknType === 'value')) && (price.discount !== '')) {
      if (price.coupon_type === 'by_fixed') {
        value = parseFloat(price.discount).toFixed(2);

        if (value.slice(-3) === '.00') {
          value = value.slice(0, -3);
        }
        value = price.currency_symbol + value + ' off';
      }
      else {
        let discount = price.discount;
        discount = discount * 100;
        discount = Math.floor(discount / 50) * 50;
        discount = discount / 100;

        let decimal_value = parseFloat(discount).toFixed(2);
        let values = decimal_value.split('.');
        value = values[0] + '% off';
      }
    }
    // Free trials
    if (elem.dataset.qknType === 'billingCycle') {
      if (price.free_trial) {
        value = ' for ' + price.trial_period;
      }
    } 
    if (elem.dataset.qknType === 'billed') {
      if (price.free_trial) {
        value = price.renewal_price + ' /month after free trial. Billed annually.';
      }
    }    
    if (elem.dataset.qknType === 'inline-billed') {
      if (price.free_trial) {
        value = '& then ' + price.renewal_price + ' / month';
      }
    }    
    if (((elem.dataset.qknType === 'percent') || (elem.dataset.qknType === 'value')) && (price.free_trial)) {
      value = 'Free trial';
    }    
    
    return value;
  }


  /*
   *  Given a price object and a DOM element, return the classes that should be applied to that element
   */
  getClassName(price: any, elem: HTMLElement) : string {
    let classes = elem.className;

    if (elem.dataset.qknType === 'price') {
      classes = classes.length > 0 ? classes + ' ' + this.priceClasses : this.priceClasses;
    }
    if ((elem.dataset.qknType === 'suffix') || (elem.dataset.qknType === 'full_suffix')) {
      classes = classes.length > 0 ? classes + ' ' + this.suffixClasses : this.suffixClasses;
    }
    if (elem.dataset.qknType === 'originalPrice') {
      if (price.coupon_price !== '') {
        if (elem.dataset.qknSuffix === 'true') {
          classes = classes.length > 0 ? classes + ' ' + this.origSuffixClasses : this.origSuffixClasses;
        }
        else {
          classes = classes.length > 0 ? classes + ' ' + this.origPriceClasses : this.origPriceClasses;
        }
      }
    }
    if (elem.dataset.qknType === 'percent') {
      if (price.coupon_price !== '' || price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses : this.percentClasses;
      }
      if (price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses + ' no-after ' : this.percentClasses + ' no-after ';
      }
    }
    if (elem.dataset.qknType === 'value') {
      if (price.coupon_price !== '' || price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.valueClasses : this.valueClasses;
      }
      if (price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses + ' no-after ' : this.percentClasses + ' no-after ';
      }
    }
    return classes;
  }

  filterPricesBySubscriptions(prices: any, cookie: any) : any {   
    if (cookie == '') {
      return prices;
    }
    try {     
      if (cookie) {
        if (cookie?.subs) {
          let subscriptions = cookie?.subs;
          prices.forEach((price: any, index: number) => {
            // Only remove none free trials, we want to show free trials regardless of subscriptin status
            if (prices[index].free_trial !== true) { 
              if ((subscriptions & price.product_flag) != 0) {
                prices[index].discount = '';
                prices[index].coupon_price = '';
                prices[index].coupon_type = '';            
              }
            }
          });          
         
        }        
      }
    }
    catch (e) {      
    }
    return prices;
  }

  getCookie(cookie_name: string) : string {
    let name = cookie_name + '=';
  
    let cookies = decodeURIComponent(document.cookie).split(';');
    for (let i = 0; i < cookies.length; i++) {
      while (cookies[i].charAt(0) == ' ') {
        cookies[i] = cookies[i].substring(1);
      }
      if (cookies[i].indexOf(name) == 0) {
        return cookies[i].substring(name.length, cookies[i].length);
      }
    }
    return '';
  };

}

